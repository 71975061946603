import React from 'react';
import { Outlet } from 'react-router-dom';

const AllProjects = React.lazy(() => import('../pages/projects/List'));
const WBSBoard = React.lazy(()=> import('../pages/wbs/Board'));
const TimeCardList = React.lazy(()=>import('../pages/time-cards/List'));

export const CustomerRoutes = [
    {
        title: "Projects",
        element: <Outlet />,
        path: "projects",
        icon: "grommet-icons:projects",
        name: "Projects",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'all', icon: "carbon:request-quote", title: "All Projects", element: <AllProjects />, name: "All Projects" },
        ]
    },
    {
        title: "WBS",
        element: <Outlet />,
        path: "wbs",
        icon: "grommet-icons:projects",
        name: "WBS",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'board', icon: "carbon:request-quote", title: "All Projects", element: <WBSBoard />, name: "WBS Board" },
        ]
    },
    {
        title: "Time Cards",
        element: <Outlet />,
        path: "time-cards",
        icon: "grommet-icons:projects",
        name: "Time Cards",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'add', icon: "carbon:request-quote", title: "All Time Cards", element: <TimeCardList />, name: "Time Cards" },
            { path: 'all', icon: "carbon:request-quote", title: "All Time Cards", element: <TimeCardList />, name: "Time Cards" },
            { path: 'weekly/submitted', icon: "carbon:request-quote", title: "All Time Cards", element: <TimeCardList />, name: "Time Cards" },
        ]
    }
]